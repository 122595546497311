<template>
  <section>
    <b-row v-if="getCenterDetails">
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="d-md-flex d-block justify-content-between align-items-end"
              >
                <div>
                  <h2 class="card-cus-title">
                    تفاصيل المركز
                  </h2>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <hr style="border-top: 2px solid #525254">
                <b-row class="w-100 mb-2">
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم المركز:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getCenterDetails.id }}#
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            حالة المركز:
                          </legend></b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            <b-badge
                              pill
                              :variant="findVariant(getCenterDetails.status)"
                            >
                              {{ findStatus(getCenterDetails.status) }}
                            </b-badge>
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            اسم المركز:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getCenterDetails.name }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الترخيص:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getCenterDetails.license_number }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.email"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            البريد الالكتروني:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getCenterDetails.email }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الهاتف:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 bg-inherit text-left"
                            dir="ltr"
                          >
                            {{ getCenterDetails.phone }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.other_phone"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم هاتف اخر:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p
                            class="form-control mb-0 bg-inherit text-left"
                            dir="ltr"
                          >
                            {{ getCenterDetails.other_phone }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.photo"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            الصورة الشخصية:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <div class="image-after-upload">
                            <img
                              :src="
                                getCenterDetails.photo.full_path +
                                  getCenterDetails.photo.file_name
                              "
                              class="img-fluid"
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.center_logo"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            شعار المركز:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <div class="image-after-upload">
                            <img
                              :src="
                                getCenterDetails.center_logo.full_path +
                                  getCenterDetails.center_logo.file_name
                              "
                              class="img-fluid"
                            >
                          </div>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.address"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            العنوان:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p class="form-control mb-0 bg-inherit text-left">
                            {{ getCenterDetails.address }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.working_days.length > 0"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            اوقات الدوام:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p class="form-control mb-0 bg-inherit text-left">
                            <template
                              v-for="(item, index) in getCenterDetails.working_days"
                            >
                              {{ item }}
                              <span
                                v-if="index !== getCenterDetails.working_days.length - 1"
                                class="m-0 p-0"
                              >-</span>
                            </template>
                          </p>
                          <p class="form-control mb-0 bg-inherit text-left">
                            {{ getCenterDetails.working_hours }}
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.platform_ratio"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            نسبة منصة مؤهل:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p class="form-control mb-0 bg-inherit text-left">
                            {{ getCenterDetails.platform_ratio }}%
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <b-col
                    v-if="getCenterDetails.description"
                    md="12"
                  >
                    <fieldset class="form-group">
                      <b-row class="w-100">
                        <b-col
                          md="2"
                          class="d-flex align-items-center"
                        >
                          <legend class="mb-1 bv-no-focus-ring col-form-label pt-0">
                            الوصف:
                          </legend>
                        </b-col>
                        <b-col md="8">
                          <p class="form-control mb-0 bg-inherit text-left">
                            {{ getCenterDetails.description }}%
                          </p>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class=""
              >
                <!-- <hr style="border-top: 2px solid #525254" /> -->
                <h2 class="card-cus-title">
                  الحجوزات
                </h2>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <hr style="border-top: 2px solid #525254">
                <Bookings :center-id="getCenterDetails.id" />
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BCardHeader,
  BCollapse,
  VBToggle,
  BFormTextarea,
  BAlert,
} from 'bootstrap-vue'
import Bookings from '../../components/Booking.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    FormWizard,
    TabContent,
    BFormFile,
    BCardHeader,
    BCollapse,
    VBToggle,
    BFormTextarea,
    BAlert,
    Bookings,
  },
  data() {
    return {
      statusList: [
        {
          id: 1,
          title: 'نشط',
        },
        {
          id: 2,
          title: 'موقف',
        },
      ],
      statusBookingsList: [
        {
          id: 1,
          title: 'في انتظار الدفع',
        },
        {
          id: 2,
          title: 'سحب العينات',
        },
        {
          id: 3,
          title: 'الاشعة',
        },
        {
          id: 4,
          title: 'الطبيب',
        },
        {
          id: 5,
          title: 'إجراء التحاليل',
        },
        {
          id: 6,
          title: 'مسؤول المختبر',
        },
        {
          id: 7,
          title: 'مؤهل',
        },
        {
          id: 8,
          title: 'غير مؤهل',
        },
      ],
      tableColumns: [
        { key: 'id', label: 'رقم الحجز', sortable: false },
        { key: 'name', label: 'اسم العميل', sortable: false },
        { key: 'phone', label: 'رقم الموبايل', sortable: false },
        { key: 'analyses_title', label: 'التحليل', sortable: false },
        { key: 'gender', label: 'الجنس', sortable: false },
        { key: 'created_at', label: 'تاريخ الحجز', sortable: false },
        { key: 'status', label: 'الحالة', sortable: false },
        { key: 'actions', label: ' ' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCenterDetails: 'getCenterDetails',
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      loadCenterDetails: 'loadCenterDetails',
    }),
    getFormatDate(date) {
      const d = new Date(date)
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
      return `${ye}-${mo}-${da}`
    },
    findStatus(data) {
      const object = this.statusList.find(item => (item.id === data ? item.title : null))
      return object.title
    },
    findVariant(data) {
      if (data === 2) {
        return 'light-warning'
      }
      if (data === 1) {
        return 'light-success'
      }
    },
    findBookingsStatus(data) {
      const object = this.statusBookingsList.find(item => (item.id === data ? item.title : null))
      return object.title
    },
    findBookingsVariant(data) {
      if (data === 1) {
        return 'light-warning'
      }
      if (data === 2) {
        return 'light-info'
      }
      if (data === 3) {
        return 'light-info'
      }
      if (data === 4) {
        return 'light-info'
      }
      if (data === 5) {
        return 'light-info'
      }
      if (data === 6) {
        return 'light-info'
      }
      if (data === 7) {
        return 'light-success'
      }
      if (data === 8) {
        return 'light-danger'
      }
    },
  },
  mounted() {
    this.loadCenterDetails(this.$router.currentRoute.params.id)
  },
}
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
